//changed

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Alert from "react-bootstrap/Alert";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import axios from "axios";

const Mars = ({ data }) => {
  const [weatherMinBarcelona, setWeatherMinBarcelona] = useState(null);
  const [weatherMaxBarcelona, setWeatherMaxBarcelona] = useState(null);
  const [weatherFeelsLikeBarcelona, setWeatherFeelsLikeBarcelona] =
    useState(null);
  const [loading, setLoading] = useState(false);

  const getWeatherBarcelona = async () => {
    try {
      await axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?q=barcelona&units=metric&appid=${process.env.GATSBY_OPENWEATHERAPI}`
        )
        .then((res) => {
          // console.log(res);
          setWeatherMinBarcelona(res.data.main.temp_min);
          setWeatherMaxBarcelona(res.data.main.temp_max);
          setWeatherFeelsLikeBarcelona(res.data.main.feels_like);
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  const [dataLatestNews, setGetDataLatestNews] = useState({
    body: "",
    title: "",
  });
  const [newNews, setNewNews] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(`${process.env.GATSBY_DRUPAL_URL}/elias_barber`)
          .then((res) => {
            setGetDataLatestNews(res.data.data[0].attributes);
          });
        setNewNews(true);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);
  const { title, body, relationships, field_image } = data.allNodePage.nodes[0];
  const thumbnail = getImage(
    relationships.field_image.localFile.childImageSharp
  );
  useEffect(() => {
    getWeatherBarcelona();
  }, []);

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <Layout>
      <Seo
        title={title}
        keywords={["andrey bond", "gatsby", "web developer", "barcelona"]}
      />
      {newNews && (
        <>
          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{dataLatestNews.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                dangerouslySetInnerHTML={{
                  __html: dataLatestNews.body.processed,
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Понятно
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <Container fluid="lg">
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ backgroundColor: "#da79795e" }}
          >
            <div className="shadow-sm bg-secondary">
              <GatsbyImage image={thumbnail} alt={field_image.alt} />
            </div>
          </Col>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc={title} className="fadein" />
            <div
              dangerouslySetInnerHTML={{
                __html: body.processed,
              }}
            />
            <div className="pt-5">
              {loading ? (
                <Alert variant="success">
                  <p>
                    Мин.температура в Барселоне{" "}
                    <b>{Math.round(weatherMinBarcelona)}&deg;C</b>
                  </p>
                  <p>
                    Макс.температура в Барселоне{" "}
                    <b>{Math.round(weatherMaxBarcelona)}&deg;C</b>
                  </p>
                  <p>
                    Ощущается как{" "}
                    <b>{Math.round(weatherFeelsLikeBarcelona)}&deg;C</b>
                  </p>
                </Alert>
              ) : (
                <Alert variant="danger">
                  <Spinner animation="border" size="xl" as="span" />
                  <span className="pl-3 font-weight-bold">Loading...</span>
                </Alert>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const query = graphql`
  {
    allNodePage(filter: { path: { alias: { eq: "/weather" } } }) {
      nodes {
        id
        title
        body {
          processed
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        field_image {
          alt
        }
      }
    }
  }
`;

export default Mars;
